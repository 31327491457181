/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('a').each(function(){
          var href = $(this).attr('href');
          var ext = href.substring(href.length - 3);

          if (ext === 'pdf') {
            $(this).append('&nbsp; <i class="fa fa-file-pdf-o" aria-hidden="true"></i>');
          } else if (ext === 'doc') {
            $(this).append('&nbsp; <i class="fa fa-file-text-o" aria-hidden="true"></i>');
          }
        });

        var collapseTimeline = function (){
          var screensize = Math.max($(window).width(), window.innerWidth),
            expItem = $('.collapse:not(:first)', '#experience');
          if (screensize < 768){
            expItem.collapse('show');

          }
          else{
            expItem.collapse('hide');
          }
        };

        // Call collapseTimeline() when window is loaded.
        $(window).load(function(){
          collapseTimeline();
        });

        // Call collapseTimeline() when window is resized.
        $(window).resize(function(){
          collapseTimeline();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var Overlay;

        function initMap() {
          var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 6,
            center: {lat: 39.90, lng: -77.39}
          });

          var imageBounds = {
            north: 43.74627357873044,
            south: 35.89482572250618,
            east: -73.80618106242308,
            west: -81.52242356607387
          };

          Overlay = new google.maps.GroundOverlay('/wp-content/themes/afcb/dist/images/Layer0_0.png',imageBounds);

          Overlay.setMap(map);

          $.getJSON( "markers.json", function( data ) {
            var infoWindow = new google.maps.InfoWindow();
            for (var i = 0; i < data.length; i++) {
              var obj = data[i];
              if(!obj.latitude) {continue;}

              var info = '<h5>' + obj.Locality + ', ' + obj.State + '</h5>';
              info += '<table class="table" style="margin-bottom:0">';
              if(obj['% Existing Canopy']){
                info += '<tr>';
                info += '<td>Existing Canopy (%)</td>';
                info += '<td>' + obj['% Existing Canopy'] + '</td>';
                info += '</tr>';
              }
              if(obj['% Canopy Goal']){
                info += '<tr>';
                info += '<td>Canopy Goal (%)</td>';
                info += '<td>' + obj['% Canopy Goal'] + '</td>';
                info += '</tr>';
              }
              if(obj['Local Contact - name']){
                info += '<tr>';
                info += '<td>Local Contact</td>';
                info += '<td>' + obj['Local Contact - name'] + ' ' + obj['Local Contact - email'] + '</td>';
                info += '</tr>';
              }
              if(obj['Program Website']){
                info += '<tr>';
                info += '<td>Program Website</td>';
                info += '<td><a href="' + obj['Program Website'] + '">' + obj['Program Website'] + '</a></td>';
                info += '</tr>';
              }
              info += '</table>';
              var title = obj.Locality;
              var position = new google.maps.LatLng(obj.latitude,obj.longitude);

              var marker = new google.maps.Marker({
                position: position,
                map: map,
                title: title
              });

              // Allow each marker to have an info window
              google.maps.event.addListener(marker, 'click', (function(marker, info) {
                return function() {
                  infoWindow.setContent(info);
                  infoWindow.open(map, marker);
                };
              })(marker, info));

            }

          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
